<template>
    <v-row justify="center">
        <v-dialog value="1" eager persistent width="650">
            <v-card class="page">
                <v-container class="px-13 mx-auto text-center">
                    <h1 class="page-title mt-5">Kudu Chicken Combo</h1>
                    <div class="page-price">10 SR</div>
                    <v-img src="@/assets/img/dummy/fraise.png"  class="mx-auto" max-width="320" eager  height="auto" />
                    <h2 class="item-title my-4">CHOOSE SIZE</h2>
                    <div class="            sss     aAd-flex justify-center">
                        <BaseSize v-model="size"  />
                    </div>
                     <h2 class="quantity-title my-10">CHOOSE QUANTITY</h2>
                    <div class="quantity d-flex justify-center">
                        <QuantityOption v-model="quantity" :background="false" width="300" :size="90" />
                    </div>
                    <v-card-actions class="justify-center card-action mt-15">
                        <v-btn @click="$router.push({name: 'menu.index'})" outlined color="primary" class="btn btn-cancel">Cancel</v-btn>
                        <div class="mx-3"></div>
                        <v-btn @click="$router.push({name: 'menu.index'})" color="primary" elevation="0" class="btn btn-addcart">Add</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import QuantityOption from '@/components/base/QuantityOption.vue';
import BaseSize from '@/components/base/BaseSize.vue';
export default {
    name:'AddItem',
    components:{
        QuantityOption,
        BaseSize
    },
    props:{
        item:{
            type:Object,
        }
    },
    data:()=>({
        size: 'small',
        quantity: 1,
    })    
}
</script>
<style lang="scss" scoped>
    .page{
        &-price{
            font-weight: bold;
            font-size: 48px;
            line-height: 72px;
            color: var(--v-primary-base);
        }
        &-title{
            font-weight: bold;
            font-size: 50px;
            line-height: 75px;
            color: #000000;
            span{
                font-weight: 300;
                font-size: 48px;
                line-height: 1                                                                                                                                                                                          00%;
            }
        }
        .quantity{
            &-title{
                font-weight: bold;
                font-size: 24px;
                line-height: 54px;
                color: #000000;
            }
        }
        .item{
            &-title{
                font-weight: bold;
                font-size: 24px;
                line-height: 54px;
                color: #000000;
            }
        
        }
       
        .card-action{
            .btn{
                border-radius: 20px !important;
                height: 122.75px !important;
                font-weight: bold;
                font-size: 36px;
                &-addcart{
                    width: 268px !important;
                    color: #FFFFFF !important;
                     text-transform: capitalize !important;
                }
                &-cancel{
                    border: 6px solid var(--v-primary-base);
                    text-transform: capitalize !important;
                    width: 230px !important;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>
