<template>
  <v-card flat class="card shadow px-4 py-2">
    <v-btn-toggle
      v-model="model"
      class="d-flex justify-space-between"
      borderless
      mandatory
      background-color="white"
      active-class="btn-active"
    >
      <v-btn
        v-for="(item, index) in items"
        :ripple="false"
        :key="index"
        text
        elevation="0"
        class="btn"
        >{{ item.text }}</v-btn
      >
    </v-btn-toggle>
  </v-card>
</template>
<script>
export default {
  name: "BaseSize",
  props: {
    value: {
      default: "small",
    },
  },
  computed: {
    items() {
      return [
        { text: "S", value: "small" },
        { text: "m", value: "medium" },
        { text: "L", value: "large" },
      ];
    },
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 390px;
  border-radius: 62px !important;
  .btn {
    height: 69px !important;
    width: 117.45px !important;
    border-radius: 55px !important;
    font-weight: 600;
    font-size: 36px;
    color: #888888;
    &-active {
      color: #ffffff !important;
      background: var(--v-primary-base);
    }
  }
}
</style>