<template>
  <section
    class="d-flex justify-space-between align-center"
    :style="{ width: `${width}px` }"
  >
    <v-btn
      fab
      elevation="0"
      class="mx-3"
      color="primary"
      :height="size"
      :width="size"
      @click.stop.prevent="minus"
      ><v-icon :color="textColor" x-large>mdi-minus</v-icon></v-btn
    >
    <div :class="[background ? 'quantity-count' : 'quantity-count-small']">
      {{ quantity }}
    </div>
    <v-btn
      fab
      elevation="0"
      class="mx-3"
      color="primary"
      :height="size"
      :width="size"
      :disabled="total == maximum && strict"
      @click.stop.prevent="add"
      ><v-icon :color="textColor" x-large>mdi-plus</v-icon></v-btn
    >
  </section>
</template>
<script>
export default {
  name: "QuantityOption",
  props: {
    width: {
      type: String,
    },
    size: {
      type: Number,
      default: 69,
    },
    value: {
      type: Number,
      default: 1,
    },
    background: {
      default: true,
      type: Boolean,
    },
    plusColor: {
      default: "#2B388F",
      type: String,
    },
    minusColor: {
      default: "#2B388F",
      type: String,
    },
    textColor: {
      default: "#ffffff",
      type: String,
    },
    total: {
      type: Number
    },
    maximum: {
      type: Number
    },
    strict: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    quantity: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    minus() {
      if (this.quantity > 1) {
        this.quantity--;
        this.$emit('decrease')
      }
    },
    add() {
      this.quantity++;
      this.$emit('increase')
    },
  },
};
</script>
<style lang="scss" scoped>
.quantity {
  // background: rgba(0, 0, 0, 0.03);
  // border-radius: 80px;
  padding: 15px 5px;
  width: 70%;
  &-count {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #141414;
  }
  &-count-small {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #141414;
  }
}
</style>